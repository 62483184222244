export enum SportsEnum {
    'Football' = 1,
    'Hockey' = 2,
    'Basketball' = 3,
    'Tennis' = 5,
    'Volleyball' = 6,
    'Cybersport' = 7,
    'TableTennis' = 9,
    'UFC' = 10,
}
